/**
 * @author Jasper Howard <jhoward@peppermillreno.com>
 * @copyright Copyright (C) 2023, Peppermill Inc. All rights reserved. Redistribution and use in source and binary forms, with or without modification, are not permitted.
 * @version 1.0.0
 * @changelog
 *  2023-12-28 (1.0.0) (jhoward)
 *      - Setup webpack entry point for main JS.
 */

import {createApp} from "vue";
import CookieDisclosure from './library/js/components/CookieDisclosure.vue';

require('./library/js/pepHelperFunctions');
require('./library/js/pepDateFunctions');
require('./library/js/datepicker');
require('./library/js/pepvalidate-3');
require('./library/js/main-v3');

// @ts-ignore
const rootNode = document.querySelector('#cookie_disclosure_vue_app');
if (rootNode) {
    const app = createApp(CookieDisclosure, {privacyPolicyPath: '/resort/privacy-policy/'});

    app.mount(rootNode);
}