/*!
 * Copyright (C) 2023, Peppermill Inc.
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are not permitted.
 *//*
 * @version 1.0.0
 * @author Jasper Howard <jhoward@peppermillcas.com>
 * @changelog
 *		2023-11-22 (1.0.0)
 *			- Refactor pepDateFunctions from main.js for easier cross-site maintenance.
 */

window.pepDateFunctions	=	{
  treatAsUTC		:	function (date) {
    let result = new Date(date);

    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());

    return result;
  },
  daysBetween		:	function (startDate, endDate) {
    let millisecondsPerDay = 24 * 60 * 60 * 1000;

    return (pepDateFunctions.treatAsUTC(endDate) - pepDateFunctions.treatAsUTC(startDate)) / millisecondsPerDay;
  },
  addMinutes		:	function (date, minutes) {
    let result = new Date(date);

    result.setMinutes(result.getMinutes() + minutes);

    return result;

  },
  addDays			:	function (date, days) {
    let result = new Date(date);

    result.setDate(result.getDate() + days);

    return result;
  },
  addMonths		:	function (date, months) {
    let result = new Date(date);

    result.setMonth(result.getMonth() + months);

    return result;
  },
  monthsBetween	:	function (d1, d2) {
    let months	=	(d2.getFullYear() - d1.getFullYear()) * 12;

    months	-=	d1.getMonth();
    months	+=	d2.getMonth();

    return (months <= 0)
      ?	0
      :	months;
  },
  formatYYYYMMDDToDateObject: function (YYYYMMDD) {
    let matches = /(\d{4})-(\d{2})-(\d{2})/.exec(YYYYMMDD);
    if (matches) {
      let date = new Date();
      date.setFullYear(matches[1]);
      date.setMonth(parseInt(matches[2], 10) - 1);
      date.setDate(matches[3]);

      return date;
    }

    return null;
  },
  formatDateObjectYYYYMMDD	:	function (dateObject) {
    let regMatch;

    // check for valid date
    if (typeof dateObject === 'object' && typeof dateObject.getMonth === 'function') {
      regMatch	=	/(20[0-9]{2})\-([0-9]{1,2})\-([0-9]{1,2})/.exec(dateObject.getFullYear() + '-' + (dateObject.getMonth() + 1) + '-' + dateObject.getDate());

      if (!!regMatch) {
        // verify correct format for months
        if (regMatch[2].length < 2)	regMatch[2]	=	'0'	+	regMatch[2];

        // verify correct format for days
        if (regMatch[3].length < 2)	regMatch[3]	=	'0'	+	regMatch[3];

        return regMatch[1] + '-' + regMatch[2] + '-' + regMatch[3];
      }
    }

    return '';
  },
  dateSuffix: function (dayOfMonth) {
    dayOfMonth = parseInt(dayOfMonth, 10);
    return (-1 !== [1, 21, 31].indexOf(dayOfMonth))
      ? 'st'
      : (-1 !== [2, 22].indexOf(dayOfMonth))
        ?	'nd'
        : (-1 !== [3, 23].indexOf(dayOfMonth))
          ? 'rd'
          : 'th';
  },
  language	:	{
    en	:	{
      days		:	['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      daysShort	:	['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      daysMin		:	['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      months		:	['January','February','March','April','May','June', 'July','August','September','October','November','December'],
      monthsShort	:	['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      today		:	'Today',
      clear		:	'Clear',
      dateFormat	:	'mm/dd/yyyy',
      timeFormat	:	'hh:ii aa',
      firstDay	:	0
    }
  }
};