/*!
 * Copyright (C) 2023, Peppermill Inc.
 * All rights reserved.
 *
 * Redistribution and use in source and binary forms, with or without
 * modification, are not permitted.
 *//*
 * @version 1.0.0
 * @author Jasper Howard <jhoward@peppermillcas.com>
 * @changelog
 *		2023-11-22 (1.0.0)
 *			- Refactor pepHelperFunctions from main.js for easier cross-site maintenance.
 */

window.pepHelperFunctions	=	{};

window.pepHelperFunctions.getParameterByName	=	function (name) {
  name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  var regexS = "[\\?&]" + name + "(\\[\\])?=([^&#]*)";
  var regex = new RegExp(regexS, 'g');
  var matches = location.search.match(regex);
  if(matches == null) {
    return "";
  } else if (matches.length === 1) {
    return decodeURIComponent(matches[0].split('=')[1].replace(/(\+|%20)/g, " "));
  } else {
    var output = [];
    for (var i = 0, l = matches.length; i < l; i++) {
      output.push(decodeURIComponent(matches[i].split('=')[1].replace(/(\+|%20)/g, " ")));
    }
    return output;
  }


  /*var results = regex.exec(window.location.search);
  if(results == null) {
    return "";
  } else {
    return decodeURIComponent(results[1].replace(/\+/g, " "));
  }*/
};

window.pepHelperFunctions.getCookie = function (name) {
  let regex = new RegExp("(?:(?:^|.*;\\s*)" + name + "\\s*\\=\\s*([^;]*).*$)|^.*$");
  return document.cookie.replace(regex, "$1");
}

/**
 * @param {string} name
 * @param {string} value
 * @param {string|Date|null} expires
 * @param {string|null} path
 * @param {string|null} domain
 */
window.pepHelperFunctions.setCookie = function (name, value, expires, path, domain) {
  if ('string' === typeof expires) {
    if ('' === expires) {
      expires = null;
    } else {
      expires = new Date(expires);
    }
  }
  document.cookie = name + '=' + value +
    ((path) ? ';path=' + path : '') +
    ((domain) ? ';domain=' + domain : '') +
    ((expires) ? ';expires=' + expires.toGMTString() : '') +
    ';secure';
}

//SOURCE: https://stackoverflow.com/questions/2144386/how-to-delete-a-cookie
window.pepHelperFunctions.deleteCookie = function (name, path, domain) {
  if (pepHelperFunctions.getCookie(name)) {
    document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
  }
}

/**
 *	Returns a representation of a (float) number from a supplied dollar amount.
 *
 *	@param	string			string	The formatted dollar amount.
 *	@param	returnAsFloat	boolean	If TRUE, a (float) will be returned, if FALSE or undefined a (string) will be returned.
 *
 *	@return					mixed	Depends on the returnAsFloat parameter.
 */
window.pepHelperFunctions.parseDollarAmount	=	function (string, returnAsFloat) {

  if (typeof string !== 'string' && typeof string.toString !== 'undefined')	string	=	string.toString();

  if (returnAsFloat)	return parseFloat(string.replace(/[^0-9\.]+/g, ''));
  else				return string.replace(/[^0-9\.]+/g, '');

}

/**
 *	Returns a dollar amount without the dollar sign. If everything to the right of the radix point is zero, it is omitted.
 *
 *	@param	amount		mixed	This value is parsed as a float.
 *	@param	truncate	boolean	If TRUE, turn 4.00 into 4.
 *
 *	@return				mixed	(float) on success, original value on error.
 */
window.pepHelperFunctions.formatAsDollarAmount	=	function (amount, truncate) {

  if ('boolean' !== typeof truncate) var truncate = true;

  var tmp	=	[];

  try {

    if (typeof amount === 'string')	amount	=	amount.replace(/[\$,]/g, '');

    var formattedAmount	=	parseFloat(amount);

    if (true === truncate && formattedAmount.toFixed(2) == parseInt(formattedAmount, 10)) {

      formattedAmount	=	parseInt(formattedAmount, 10);

    } else {

      formattedAmount	=	formattedAmount.toFixed(2);

    }

    formattedAmount	=	'' + formattedAmount;

    if (formattedAmount.indexOf('.') > -1) {

      tmp		=	formattedAmount.split('.');

    } else {

      tmp[0]	=	formattedAmount;

    }

    tmp[0]	=	tmp[0].replace(/([0-9])(?=([0-9]{3})+)/g, '$1,');

    formattedAmount	=	tmp.join('.');

    return formattedAmount;

  } catch (e) {

    return amount;

  }

}

/**
 * @param {string} storageKey
 * @param {{checkin: *, code: *, iata: (*|default.methods.$refs.iataInput.value), exp, checkout: *}} data
 * @param {boolean} forceCookie
 */
window.pepHelperFunctions.setCachedData	=	function (storageKey, data, forceCookie) {

  if (typeof storageKey !== 'string') return false;
  if (typeof data == 'undefined') return false;

  if (typeof forceCookie !== 'boolean') {
    forceCookie = false;
  }

  if ('JSON' in window) {
    if ('localStorage' in window && false === forceCookie) {
      return localStorage.setItem(storageKey, JSON.stringify({
        TIMESTAMP	:	new Date().getTime(),
        DATA		:	data
      }));
    } else {
      var cookieData	=	$('html').data('cookie');
      if (cookieData) {
        pepHelperFunctions.setCookie(
          storageKey,
          JSON.stringify({ TIMESTAMP : new Date().getTime(), DATA : data }),
          '',
          cookieData.b
        );
        //document.cookie = storageKey + '=' + JSON.stringify({ TIMESTAMP : new Date().getTime(), DATA : data }) + '; path=' + cookieData.b + '; ' + cookieData.s + '; ' + cookieData.h;
        return true;
      }
    }
  }

  return false;
}

/**
 * @param {string} storageKey
 * @param {number} storageTTL in microseconds
 * @param {boolean=} forceCookie
 */
window.pepHelperFunctions.getCachedData	=	function (storageKey, storageTTL, forceCookie) {

  if (typeof storageKey !== 'string')	return false;
  if (typeof storageTTL !== 'number')	var storageTTL	=	3600000;	//default to 1 hour

  if (typeof forceCookie !== 'boolean') {
    var forceCookie = false;
  }

  let dataSTR	=	'';
  let dataOBJ	=	{};

  try {
    if ('JSON' in window) {
      if ('localStorage' in window && false === forceCookie) {
        dataSTR	=	localStorage.getItem(storageKey);
        if (dataSTR) {
          dataOBJ	=	JSON.parse(dataSTR);
          if (dataOBJ.TIMESTAMP + storageTTL > new Date().getTime()) {
            return dataOBJ;
          } else {
            localStorage.removeItem(storageKey);
          }
        }
      } else {
        //look for cookie
        dataSTR = pepHelperFunctions.getCookie(storageKey);
        if (dataSTR.length) {
          dataOBJ	=	JSON.parse(dataSTR);
          if (dataOBJ.TIMESTAMP + storageTTL > new Date().getTime()) {
            return dataOBJ;
          } else {
            let cookieData	=	$('html').data('cookie');
            if (cookieData) {
              pepHelperFunctions.deleteCookie(storageKey, cookieData.b);
            }
          }
        }
      }
    }
  } catch (e) {
    console.log(e);
  }

  return false;
}

/**
 * @param {string} storageKey
 * @param {boolean} forceCookie
 */
window.pepHelperFunctions.removeCachedData	=	function (storageKey, forceCookie) {

  if (typeof storageKey !== 'string')	return false;

  if (typeof forceCookie !== 'boolean') {
    var forceCookie = false;
  }

  try {
    if ('localStorage' in window && false === forceCookie) {
      return localStorage.removeItem(storageKey);
    } else {
      var cookieData	=	$('html').data('cookie');
      if (cookieData) {
        pepHelperFunctions.deleteCookie(storageKey, cookieData.b);
        return true;
      }
    }
  } catch (e) {
    console.log(e);
  }

  return false;
}