
/**
 * @description Self-contained cookie disclosure component
 * @author Jasper Howard <jhoward@peppermillcas.com>
 * @version	1.1.2
 * @copyright	Copyright (C) 2023-2024, Peppermill Inc. All rights reserved. Redistribution and use in source and binary forms, with or without modification, are not permitted.
 * @chanagelog
 *  2023-09-18 (1.0.0) (jhoward)
 *    - Initial release for <cookie-disclosure> component.
 *
 *  2023-10-26 (1.0.1) (jhoward)
 *    - Update copy in <cookie-disclosure>.
 *
 *  2024-01-30 (1.1.0) (jhoward)
 *    - Add "refreshOnRequiredOnly" prop to <cookie-disclosure>.
 *
 *  2024-03-14 (1.1.1) (jhoward)
 *    - Reduce text size and line spacing in <cookie-disclosure>.
 *    - Display buttons side-by-side for mobile in <cookie-disclosure>.
 *    - Add minimizeUi prop to <cookie-disclosure> as option to enable smaller text size and tighter layout.
 *
 *  2024-04-16 (1.1.2) (jhoward)
 *    - Fix cookie TTL in <cookie-disclosure> so it isn't treated as a session-duration cookie.
 */
import {defineComponent} from 'vue';

interface CookieDisclosureCachedData {
  DATA: '' | 'required' | 'all';
  TIMESTAMP: number;
}

export default defineComponent({
  name: "CookieDisclosure",
  props: {
    isAllCookiesDisabled: {
      type: Boolean,
      default: false
    },
    isRequiredCookiesDisabled: {
      type: Boolean,
      default: false
    },
    refreshOnRequiredOnly: {
      type: Boolean,
      default: false
    },
    privacyPolicyPath: {
      type: String,
      default: '/about-us/privacy-policy'
    },
    minimizeUi: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      storageKey: 'cookie_disclosure',
      storageTTL: 60 * 60 * 24 * 365 * 1000,
      userSetting: null as null | string,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.isCookieAllowed = (type) => {
      if (this.userSetting && this.userSetting !== 'all') {
        return type.toLowerCase() === this.userSetting.toLowerCase();
      }

      return true;
    };

    window.setTimeout(() => {
      this.userSetting = this.getUserSetting();
    }, 132);

    if (this.minimizeUi) {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      }, {passive: true});
    }
  },
  watch: {
    userSetting() {
      this.isActive = !this.userSetting || 0 === this.userSetting.length;
    }
  },
  computed: {
    containerClasses() {
      return {
        'cookie_disclosure--container-active': this.isActive,
        'cookie_disclosure--container-minimize_ui': this.minimizeUi,
        'cookie_disclosure--container-mobile_ui': this.minimizeUi && this.windowWidth < 800
      };
    },
  },
  methods: {
    getUserSetting(): string | null {
      const cachedData = window.pepHelperFunctions.getCachedData(this.storageKey, this.storageTTL, true) as false | CookieDisclosureCachedData;
      if (false !== cachedData) {
        return cachedData.DATA;
      }

      return '';
    },
    setUserSetting(value: string) {
      const cookieData	=	JSON.parse(document.querySelector('html')?.dataset.cookie || 'null');
      if (cookieData) {
        window.pepHelperFunctions.setCookie(
          this.storageKey,
          JSON.stringify({TIMESTAMP: new Date().getTime(), DATA: value}),
          new Date((new Date()).getTime() + this.storageTTL),
          cookieData.b
        );
      }
      this.userSetting = value;

      if (this.userSetting === 'required' && this.refreshOnRequiredOnly) {
        setTimeout(() => {
          window.location.reload();
        }, 132);
      }
    }
  }
})
